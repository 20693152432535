import React from 'react';
import LineIcon from "react-lineicons";
import {Cpu, Camera, Smartphone, Image, Grid, Globe, Radio} from 'react-feather';
import { Fade } from 'react-reveal';

function Service(props){

  const renderAuthButton = (name)=>{
    switch(name){
      case 'cpu': return <Cpu size={42}/>
      case 'mobile': return <Smartphone size={42}/>
      case 'camera': return <Camera size={42}/>
      case 'image': return <Image size={42}/>
      case 'grid': return <Grid size={42}/>
      case 'globe': return <Globe size={42}/>
      case 'radio': return <Radio size={42}/>
      default: return <LineIcon name={props.content.icon} />
    }
  }

  return (
    <Fade wait={50} bottom>
      <div className="mi-service">
        <span className="mi-service-icon">
          {renderAuthButton(props.content.icon)}
        </span>
        <h5>{props.content.title}</h5>
        <p>{props.content.details}</p>
      </div>
    </Fade>
  )
}

export default Service;
