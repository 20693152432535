import React from "react";
import Particles from "react-particles-js";
import Layout from "../components/Layout";
import { getLang } from '../data/setLang'
import { Fade, Flip } from 'react-reveal';
import {Send} from "react-feather";

function RealSamesta(){
  const lang = getLang();
  const paramConfig = {
    particles: {
      number: {
        value: 130,
        density: {
          enable: true
        }
      },
      color: {
        value: "#ffffff"
      },
      opacity: {
        value: 0.1
      },
      size: {
        value: 6,
        random: true,
        anim: {
          enable: true,
          speed: 3
        }
      },
      line_linked: {
        enable: false
      },
      move: {
        random: true,
        speed: 1,
        direction: "top",
        out_mode: "out"
      }
    }
  };

  return (
    <Layout>
      <Particles className="mi-home-particle" params={paramConfig} />
      <div className="mi-home-area mi-padding-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="mi-home-content">
                <Fade wait={50} bottom>
                  <h1>Brawl Stars Clan</h1>
                  <img src="/images/realSamesta_BrawlTitle.png" alt="realSamestaImg" style={{marginBottom : '15px'}}/>
                  <br></br>
                  <h4>Revisa el clan aquí: <a href="https://brl.gg/c/2RJ2G9998" rel="noopener noreferrer" target="_blank" className="active">Estadísticas</a></h4>
                  
                  <p>Clan bonito, precioso, hermoso, siempre activo y apapachador. El presi se la come entera, nada le sale todo le entra.</p>
                  <ul className="list-group">
                    <li className="list-group-item" style={{backgroundColor: 'transparent', padding: '0px'}}><b>Clan organizado:</b> En el clan se organizan equipos internos.</li>
                    <li className="list-group-item" style={{backgroundColor: 'transparent', padding: '0px'}}><b>Ponte trucha:</b> Usar todos los tickets debes, con tu equipo.</li>
                    <li className="list-group-item" style={{backgroundColor: 'transparent', padding: '0px'}}><b>Positivismo:</b> Con actitud de ayudar y subir copitas.</li>
                    <li className="list-group-item" style={{backgroundColor: 'transparent', padding: '0px'}}><b>Participa:</b> Se organizan eventos internos.</li>
                  </ul>
                </Fade>
                <div className="col-12">
                  <Flip wait={50} delay={100} bottom>
                    <div className="mi-contact-infoblock">
                      <span className="mi-contact-infoblock-icon">
                        <Send/>
                      </span>
                      <div style={{textAlign: 'left'}}>
                        <h5>Telegram - Presidente</h5>
                        <h6>Raicho <a href="https://t.me/Batsi19" rel="noopener noreferrer" target="_blank">@Batsi19</a></h6>
                      </div>
                    </div>
                  </Flip>
                </div>
                <div className="col-12">
                  <Flip wait={50} delay={100} bottom>
                    <div className="mi-contact-infoblock">
                      <span className="mi-contact-infoblock-icon">
                        <Send/>
                      </span>
                      <div style={{textAlign: 'left'}}>
                        <h5>Telegram - Vice Presi</h5>
                        <h6>Papir <a href={lang.d.contactInfo.telegram} rel="noopener noreferrer" target="_blank">@oac28x</a></h6>
                      </div>
                    </div>
                  </Flip>
                </div>
                <div className="col-12">
                  <Flip wait={50} delay={100} bottom>
                    <div className="mi-contact-infoblock">
                      <span className="mi-contact-infoblock-icon">
                        <Send/>
                      </span>
                      <div style={{textAlign: 'left'}}>
                        <h5>Telegram - Vice Presi</h5>
                        <h6>Zinko_GC <a href="https://t.me/Jorge_Znk" rel="noopener noreferrer" target="_blank">@Jorge_Znk</a></h6>
                      </div>
                    </div>
                  </Flip>
                </div>
                <div className="col-12">
                  <Flip wait={50} delay={100} bottom>
                    <div className="mi-contact-infoblock">
                      <span className="mi-contact-infoblock-icon">
                        <Send/>
                      </span>
                      <div style={{textAlign: 'left'}}>
                        <h5>Telegram - Vice Presi</h5>
                        <h6>·Hyperion· <a href="https://t.me/Hyperi0n72" rel="noopener noreferrer" target="_blank">@Hyperi0n72</a></h6>
                      </div>
                    </div>
                  </Flip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default RealSamesta;
