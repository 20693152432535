import React from "react";

import { Fade } from 'react-reveal';

function Sectiontitle(props) {
  return (
    <Fade wait={10}>
      <div className="mi-sectiontitle">
        <h2>{props.title}</h2>
        <span>{props.title}</span>
      </div>
    </Fade>
  );
}

export default Sectiontitle;
