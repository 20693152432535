import React from "react";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import Service from "../components/Service";
import { Fade } from 'react-reveal';
import { getLang } from '../data/setLang'

function About() {
  const lang = getLang();

  return (
    <Layout>
      <div className="mi-about-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title={lang.d.titles.about} />
          <Fade wait={10} delay={100}>
            <div className="row">
              <div className="col-lg-6">
                <div className="mi-about-image">
                  <img src={lang.d.information.aboutImage} alt="about" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mi-about-content">
                  <h3>
                  {lang.d.information.aboutRows.i} <span className="color-theme">{lang.d.information.name}</span>
                  </h3>
                  <p>
                    {lang.d.information.aboutSumary}
                  </p>
                  <ul>
                    <li>
                      <b>{lang.d.information.aboutRows.nam}</b> {lang.d.information.name}
                    </li>
                    <li>
                      <b>{lang.d.information.aboutRows.tit}</b> {lang.d.information.title}
                    </li>
                    <li>
                      <b>{lang.d.information.aboutRows.age}</b> {lang.d.information.age} {lang.d.information.aboutRows.an}
                    </li>
                    <li>
                      <b>{lang.d.information.aboutRows.nat}</b> {lang.d.information.nationality}
                    </li>
                    <li>
                      <b>{lang.d.information.aboutRows.lan}</b> {lang.d.information.language}
                    </li>
                    <li>
                      <b>{lang.d.information.aboutRows.ema}</b> {lang.d.information.email}
                    </li>
                    <li>
                      <b>{lang.d.information.aboutRows.add}</b> {lang.d.information.address}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div className="mi-service-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title={lang.d.titles.services} />
          <div className="mi-service-wrapper">
            <div className="row mt-30-reverse">
              {lang.d.services.map((service) => (
                <div
                  className="col-lg-4 col-md-6 col-12 mt-30"
                  key={service.title} >
                  <Service content={service} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default About;
