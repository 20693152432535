const database_es = {
  lang:"es",
  titles:{
    about: 'Acerca de mí',
    services: 'Conocimiento y experiencia',
    skills: 'Mis habilidades',
    contact: 'Contactame'
  },
  buttons:{
    gotoapp: "Ir al sitio de la app"
  },
  menu:{
    home: 'inicio',
    about: 'acerca de mí',
    resume: 'currículum',
    portfolios: 'portafolios',
    blogs: 'blogs',
    contact: 'contacto'
  },
  notFound:{
    number:'404',
    message: '¡Página no encontrada! Aquí no hay nada',
    advice: '¿Por qué no intentas con otra dirección?',
    button: 'Ir a inicio'
  },
  information: {
    name: 'Oscar Aguilar',
    hiContent: '¡Hola! Soy',
    aboutContent: "Soy un ingeniero electrónico apasionado por la tecnología, fotografía y naturaleza. He trabajado con aplicaciones de escritorio, web, móviles y embebidas. Tengo experiencia optimizando aplicaciones, hacinedo uso de patrones de diseño y mejores prácticas. Hey !Pasa a ver mis fotos en 500px!",
    aboutSumary: "Cuento con experiencia en desarrollo móvil, escritorio, web y embebidio. Realmente me gusta aprender de tecnología, tomar fotos y la edición.",
    aboutSmal: '',
    title: "Ingeniero en Comunicaciones y Electrónica",
    age: new Date(new Date() - new Date('1992/09/28')).getFullYear() - 1970,
    phone: '',
    nationality: 'Mexicano',
    language: 'Español, Inglés',
    email: 'oscar_aglr@outlook.com',
    address: 'Colima, Colima. México',
    freelanceStatus: '', //'Available, part-time',
    socialLinks: {
      linkedin: 'https://www.linkedin.com/in/oac28x/',
      github: 'https://github.com/oac28x/',
      fotopx: 'https://500px.com/p/oac28x/',
      facebook: 'https://www.facebook.com/oac28x',
      twitter: '',
      pinterest: '',
      behance: '',
      dribbble: ''
    },
    aboutRows:{
      i: 'Soy',
      tit: 'Carrera',
      nam: 'Nombre',
      age: 'Edad',
      ani: 'Años',
      pho: 'Teléfono',
      nat: 'Nacionalidad',
      lan: 'Lenguages',
      ema: 'Email',
      add: 'Dirección',
      dow: 'Descargar CV'
    },
    headerBg: '/images/header_bg.png',
    headerImage: '/images/my-image.png',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/empty.pdf'
  },
  services: [
    {
      title: "Desarrollo Móvil",
      icon: 'mobile',
      details: "Desarrollo multiplataforma con Xamarin, Xamarin Froms - Xamarin Native. Android e iOS nativo."
    },
    {
      title: "Desarrollo Web",
      icon: 'code',
      details: "Desarrollo de aplicaciones web estáticas y dinámicas, SPA utilizando ReactJs o Angular. Esta página la hice usando ReactJs."
    },
    {
      title: "Desarrollo Embebido",
      icon: 'grid',
      details: "Experiencia utilizando PIC's, Atmel, Arduinos. GPIO, protocólos de comunicación, registros, timers, hojas de datos, etc."
    },
    {
      title: "Diseño Electrónico",
      icon: 'cpu',
      details: "Diseño de circuitos electrónicos análogos y digitales. Experiencia utilizando herramientas electrónicas de medición y prueba."
    },
    {
      title: "Comunicaciones Inalámbricas",
      icon: 'radio',
      details: "Conocimiento sobre radiocomunicaciones, polarización, diversidad, potencia de radios, antenas, sensibilidad, ganancia, etc."
    },
    {
      title: "Diseño de imágenes",
      icon: 'image',
      details: "Diseño de fotos y edición, Photoshop como principal herrameinta. Creación de imágenes para uso en aplicaciones varias."
    },
    {
      title: "Disñeo FrontEnd",
      icon: 'color-pallet',
      details: "Experiencia sobre experiencia de usuario, identidad de aplicación, colores, animaciones, flujos intuitivos y utilizables."
    },
    {
      title: "Fotografía",
      icon: 'camera',
      details: "Capturar momentos de luz, colores vivos, naturaleza, edificios y personas. Postproducción con Lightroom y Photoshop."
    },
    {
      title: "IoT y Redes",
      icon: 'globe',
      details: "Familiarizadon con Internet de las Cosas, subnetting y redes."
    }
  ],
  apps: [
    {
      icon: "/applogos/imei_app_logo.png",
      link: "https://play.google.com/store/apps/details?id=com.oscaraaguilar.mx.imei",
      title: 'IMEI',
      subtitle: 'Para Android',
      button:'Descargar app',
      details: [
        'Si gustas conocer los datos que identifican tu dispositivo móvil, solo descarga esta aplicación. La app muestra de forma sencilla estos datos.',
        'Guardar los datos es muy sencillo, cuentas con varias opciones para ello. Al hacer clic en cualquier recuadro de información, esta se copia al portapapeles de tu dispositivo. De igual forma se tiene la opción de guardar los datos en un archivo de texto, incluso guardarlo en Drive.',
        'Puedes calificar la app y dejar comentarios en Play Store, gracias por usar nuestra aplicación. ',
      ] 
    },
    {
      icon: "/applogos/res_calc_app_logo.png",
      link: '',
      title: 'Resistor Calculator',
      subtitle: 'Proximamente',
      button:'Descargar app',
      details: [
        'Si eres apasionado por la electrónica, quieres aprender o día a día necesitas calcular valores de resistencias con valores comerciales y/o reales, esta applicación te facilitará de forma interactiva modificar valores de resistencias mediante anillos de colores. También cuenta con apartado en el cual se pueden guardar, para futuras referencias, inventarios o proyectos; te permite agregar comentarios a cada elemento.',
        'En un apartado, puedes calcular valores de resistencias con diferentes rangos de error usando valores reales, en arreglos serie o paralelo. Se despliegan opciones con su respectivo error. Seguro te será muy útil.',
        'Calculadora de resistencias con código de colores de 4, 5 y 6 bandas. Historial de resistencias. Calculadora de resistencias con valores comerciales.'
      ] 
    }
  ],
  privacy: {
    titles: {
      apps: 'Applications',
      privacy: 'Privacy Policy',
      links: 'External Links',
      contact: 'Contact Us',
      changes: 'Changes to our Privacy Policy',
      admobpolicy: 'https://support.google.com/admob/answer/6128543?hl=en',
      appcenterpolicy: 'https://privacy.microsoft.com/en-us/privacystatement',
      urlLink: 'link',
      email: 'oscar_aguilar_apps@outlook.com',
      baseurl: 'https://www.oscaraguilar.info/apps_privacy-policy#privacy_policy',
    },
    privacydata:[
      'Fecha efectiva: 12 de Enero del 2020. Última revisión en agosto 14 del 2021.',
      'Esta política de privacidad establece los términos en los que las aplicaciones móviles que se ofrecen, utilizan y protegen la información proporcionada por nuestros usuarios cuando las utilizan. Por favor, tómese un momento para familiarizarse con nuestras prácticas de privacidad. Estamos comprometidos con la seguridad de los datos de nuestros usuarios. En cualquier momento, puede restringir el uso de la información personal que se proporciona. En ningun momento se recopilan datos personales o de otra índole.',
      'AL INSTALAR LA APLICACIÓN EN SU DISPOSITIVO MÓVIL, ENTRAR, CONECTARSE, ACCEDER Y/O UTILIZAR LA APLICACIÓN, USTED ACEPTA LOS TÉRMINOS Y CONDICIONES ESTABLECIDOS EN ESTA POLÍTICA DE PRIVACIDAD. TENGA EN CUENTA: SI USTED O, SEGÚN SEA APLICABLE, SU TUTOR LEGAL, NO ESTÁ DE ACUERDO CON CUALQUIER TÉRMINO ESTABLECIDO EN ESTE DOCUMENTO, NO PUEDE INSTALAR, ACCEDER Y/O UTILIZAR LAS APLICACIONES Y SE LE SOLICITA QUE LAS BORRE INMEDIATAMENTE, Y CUALQUIER PARTE DE LAS MISMAS DE SU DISPOSITIVO MÓVIL.',
      'Las aplicaciones pueden requerir acceso a funciones especiales del dispositivo, se pedirá el permiso mediante un cuandro de dialogo cuando sea necesario. En cualquier momento puede otorgar o cancelar el acceso a esas funciones.',
    ],
    linksdata:[
      'La aplicación puede contener enlaces a sitios externos que pueden ser de su interés. Una vez que hace clic en estos enlaces y abandona la aplicación, ya no tenemos control sobre el sitio al que es redirigido, por lo tanto, no somos responsables de los términos y la privacidad de la protección de datos de los usuarios. Estos sitios tienen sus propias políticas de privacidad, por lo que se recomienda que las lea para confirmar que está de acuerdo con ellas.',
      'Más específicamente, utilizamos Google Admob para mostrar anuncios. Puede consultar su política de privacidad aquí:',
      'Las aplicaciones utilizan Microsoft App Center, análisis y diagnósticos para ayudarnos a analizar el uso de nuestras aplicaciones. Nos ayuda a mejorar nuestros productos. Puede leer más sobre la política de privacidad de Microsoft aquí:'
    ],
    changesdata:[
      'Esta Política de privacidad puede ser actualizada de vez en cuando por cualquier motivo, le notificaremos de cualquier cambio en nuestra política de privacidad publicando la nueva política de privacidad en',
      'Su uso continuo de las aplicaciones y servicios después de la publicación de cambios significará que acepta esos cambios.',
    ],
    contact: 'Si tiene alguna pregunta con respecto a la privacidad de los datos mientras usa las aplicaciones, o tiene una pregunta sobre nuestras prácticas, comuníquese con nosotros por correo electrónico a'
},
  skills: [
    {
      title: ".Net y NetCore",
      value: 85
    },
    {
      title: "Desarrollo Xamarin",
      value: 90
    },
    {
      title: "Bootstrap, HTML5 y CSS3",
      value: 90
    },
    {
      title: "Android e iOS nativo",
      value: 65
    },
    {
      title: "Javascript",
      value: 60
    },
    {
      title: "jQuery",
      value: 75
    },
    {
      title: "ReactJS",
      value: 80
    },
    {
      title: "Angular",
      value: 65
    },
    {
      title: "Git",
      value: 80
    },
    {
      title: "Azure DevOps",
      value: 70
    },
    {
      title: "Photoshop",
      value: 70
    },
    {
      title: "Lightroom",
      value: 90
    },
    {
      title: "Planeación radiocomunicaciones",
      value: 70
    },
    {
      title: "Diseño PCB",
      value: 90
    },
    {
      title: "Programación embebida - C++",
      value: 70
    },
    {
      title: "Diseño electronico y resolución",
      value: 80
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2018 - Present",
        position: "Frontend Web Developer",
        company: "Abc Company",
        details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      },
      {
        id: 2,
        year: "2016 - 2018",
        position: "Frontend Web Developer",
        company: "CBA Company",
        details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      },
      {
        id: 3,
        year: "2014 - 1016",
        position: "UI/UX Designer",
        company: "Example Company",
        details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2018 - 2019",
        graduation: "Master of Science",
        university: "Abc University",
        details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      },
      {
        id: 2,
        year: "2016 - 2018",
        graduation: "Bachelor of Science",
        university: "Abc University",
        details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      },
      {
        id: 3,
        year: "2015 - 2016",
        graduation: "Higher Schoold Graduation",
        university: "Abc College",
        details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      }
    ]
  },
  contactInfo: {
    subtitle: 'Ponerse en contacto',
    nameField: 'Tú nombre',
    emailField: 'Tú correo electrónico',
    subjectField: 'Asunto',
    messageField: 'Mensaje',
    emailInfo: 'Correo electrónico',
    addressInfo: 'Dirección',
    telegramInfo: 'Telegram',
    emailAddress: ['oscar_aguilar_apps@outlook.com', 'oscar_aglr@outlook.com'],
    address: 'Colima, Colima. México',
    telegram: 'https://t.me/oac28x',
    sendButton: 'Enviar',
    alerts: {
      nameRequired: 'Nombre es requerido.',
      emailRequired: 'Correo electrónico es requerido.',
      subjectRequired: 'Asunto es requerido.',
      messageRequired: 'Mensaje es requerido.',
      validEmail: 'Ingresa un email valido.',
      invalidCaptcha: 'Invalid Captcha.',
      generalError: 'Algo salió mal.',
      mesageSent: 'Tu mensaje se envió.'
    }
  }
  }

export default database_es;