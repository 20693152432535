import React from "react";
import LineIcon from 'react-lineicons';
import { getLang } from '../data/setLang'

function Socialicons(props){
  const lang = getLang();

  return (
    <ul className={props.bordered ? 'mi-socialicons mi-socialicons-bordered' : 'mi-socialicons'}>
      {!lang.d.information.socialLinks.linkedin ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={lang.d.information.socialLinks.linkedin}>
          <LineIcon name="linkedin"/>
        </a>
      </li>}
      {!lang.d.information.socialLinks.github ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={lang.d.information.socialLinks.github}>
          <LineIcon name="github"/>
        </a>
      </li>}
      {!lang.d.information.socialLinks.fotopx ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={lang.d.information.socialLinks.fotopx}>
          <LineIcon name="500px"/>
        </a>
      </li>}
    </ul>
  );
}

export default Socialicons;
