import React from "react";
import TrackVisibility from "react-on-screen";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import Progress from "../components/Progress";
import { getLang } from '../data/setLang'

function Resumes(){
  const lang = getLang();
  
  return (
    <Layout>
      <div className="mi-skills-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title={lang.d.titles.skills} />
          <div className="mi-skills">
            <div className="row mt-30-reverse">
              {lang.d.skills.map(skill => (
                <TrackVisibility once className="col-lg-6 mt-30" key={skill.title}>
                  <Progress title={skill.title} percentage={skill.value} />
                </TrackVisibility>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Resumes;
