import React, { useEffect } from 'react';
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import PlaystoreApp from "../components/PlaystoreApp";
import { getLang } from '../data/setLang'

//http://localhost:3000/apps_privacy-policy#privacy_policy
//http://localhost:3000/apps_privacy-policy#external_links

function Apps() {
  const lang = getLang();

  const mailLink = <span>&nbsp;<a href={`mailto:${lang.d.privacy.titles.email}`}>{lang.d.privacy.titles.email}</a></span>;
  const privLink = <span><a href={lang.d.privacy.titles.baseurl} rel="noopener noreferrer" target="_blank">&nbsp;{lang.d.privacy.titles.baseurl}</a>.</span>;

  useEffect(() => {
    var urlHash = window.location.href.split("#")[1];
    var elementToScroll = document.getElementById(urlHash);
    if(elementToScroll)
      elementToScroll.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
  });

  return (
    <Layout>
      <div className="mi-service-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title={lang.d.privacy.titles.apps} />
          <div className="mi-service-wrapper">
            <div className="row mt-30-reverse">
                {lang.d.apps.map((app) => (
                  <div className="col-12 mt-30" key={app.title} >
                    <PlaystoreApp content={app} />
                  </div>
                ))}
            </div>
          </div>
          </div>
      </div>
      <div id="privacy_policy" className="mi-service-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title={lang.d.privacy.titles.privacy} />
          <div className="row">
            <div className="col-md-12">
              <div className="mi-about-content">
                {lang.d.privacy.privacydata.map((element, index) => ( <p align="justify" key={index}>{element}</p> ))}
              </div>
            </div>
          </div>    
        </div>
      </div>
      <div id="external_links" className="mi-service-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title={lang.d.privacy.titles.links} />
          <div className="row">
            <div className="col-md-12">
              <div className="mi-about-content">
                {lang.d.privacy.linksdata.map((element, index) => 
                { 
                  var link;
                  switch(index){
                    case 1:
                      link = <span><a href={lang.d.privacy.titles.admobpolicy} rel="noopener noreferrer" target="_blank">&nbsp;{lang.d.privacy.titles.urlLink}</a>.</span>
                    break;
                    case 2:
                      link = <span><a href={lang.d.privacy.titles.appcenterpolicy} rel="noopener noreferrer" target="_blank">&nbsp;{lang.d.privacy.titles.urlLink}</a>.</span>
                    break;
                  }
                  return <p align="justify" key={index}>{element}{link ? link : null}</p> 
                })}
              </div>
            </div>
          </div>   
        </div>
      </div>
      <div id="changes" className="mi-service-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title={lang.d.privacy.titles.changes} />
          <div className="row">
            <div className="col-md-12">
              <div className="mi-about-content">
                {lang.d.privacy.changesdata.map((element, i) => {
                  if(i === 0) return (<span key={i} align="justify">{element}{privLink}</span>);
                  else return (<span key={i} >{element}</span>)
                })}
              </div>
            </div>
          </div>    
        </div>
      </div>
      <div className="mi-service-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title={lang.d.privacy.titles.contact} />
          <div className="row">
            <div className="col-md-12">
              <div className="mi-about-content">
                <p align="justify">{lang.d.privacy.contact}{mailLink}.</p>
              </div>
            </div>
          </div>    
        </div>
      </div>
    </Layout>
  );
}

export default Apps;
