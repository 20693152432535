import React , { useState, useEffect } from 'react';
import LineIcon from "react-lineicons";
import {Maximize2, Minimize2} from 'react-feather';
import { Accordion, Card, Button } from 'react-bootstrap'
import { Fade } from 'react-reveal';

function PlaystoreApp(props){
  
  const [collapsed, setCollapsed] = useState(false);
  const renderAuthButton = (name)=>{
    setCollapsed(!collapsed);
  }

  return (
    <Accordion style={{width: "100%"}}>
      <Card style={{padding: "0px"}} className="mi-service col-12">
        <Card.Header style={{padding:"0px", display:"table", overflow:"hidden", borderBottom:"none", width:"100%"}}>
          <Accordion.Toggle as={Card.Header} eventKey={props.content.title} onClick={renderAuthButton}>
            <div style={{lineHeight:"55px", margin:".75rem 1.25rem", verticalAlign:"middle"}}>
                <div style={{width: "100%"}}>
                  <img style={{height: "55px", marginRight:"10px"}} src={props.content.icon} alt={props.content.title} />
                  <span style={{marginLeft:'10px', lineHeight:'normal', verticalAlign:'middle'}}>
                    <span style={{fontSize:"1.3em", fontWeight:"bold", lineHeight:'normal'}}>{props.content.title}</span><br/>
                    <span style={{fontSize:"0.9em", lineHeight:'normal'}}>{props.content.subtitle}</span>
                  </span>
                  <div style={{float: "right"}}>
                    {!collapsed ? <Maximize2 ize={42} /> : <Minimize2 ize={42} />}
                  </div>
                </div>
            </div>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={props.content.title}>
          <Card.Body>
              {props.content.details.map((element, index) => ( <p align="justify" style={{margin:"10px 0px"}} key={index}>{element}</p> ))}
              
              {props.content.link ? <a style={{marginTop:"15px"}} href={props.content.link} rel="noopener noreferrer" target="_blank" className="mi-button">
                  {props.content.button}
              </a> : null }
          </Card.Body>
        </Accordion.Collapse>
        {/* <div className="mi-service">
          <span className="mi-service-icon">
            {renderAuthButton(props.content.icon)}
          </span>
          <h5>{props.content.title}</h5>
          <p>{props.content.details}</p>
        </div> */}
      </Card>
    </Accordion>
  )
}

export default PlaystoreApp;
