const database_en = {
  lang:'en',
  titles:{
    about: 'About me',
    services: 'Knowledge & experience',
    skills: 'My skills',
    contact: 'Contact me'
  },
  buttons:{
    gotoapp: 'Go to app site'
  },
  menu:{
    home: 'home',
    about: 'about',
    resume: 'resume',
    portfolios: 'portfolios',
    blogs: 'blogs',
    contact: 'contact'
  },
  notFound:{
    number:'404',
    message: 'Page not found! Nothing here',
    advice: 'Meanwhile, why don\'t you try again by going',
    button: 'Back to home'
  },
  information: {
    name: 'Oscar Aguilar',
    hiContent: 'Hello there, I am',
    aboutContent: 'I am an electronics engineer passionated about technology, photography and nature. I\'ve worked with embedded, mobile, desktop & web applications. I have experience optimizing applications, using design patterns and best practices. Hey, go ahead and check my photos on 500px',
    aboutSumary: 'I have experience on mobile, desktop, web and embedded development. I really enjoy learning about technology, shooting photos and photo design.',
    aboutSmal: '',
    title: 'Electronics and Communications Engineer',
    age: new Date(new Date() - new Date('1992/09/28')).getFullYear() - 1970,
    phone: '',
    nationality: 'Mexican',
    language: 'Spanish, English',
    email: 'oscar_aglr@outlook.com',
    address: 'Colima, Colima. México',
    socialLinks: {
      linkedin: 'https://www.linkedin.com/in/oac28x/',
      github: 'https://github.com/oac28x/',
      fotopx: 'https://500px.com/p/oac28x/',
      facebook: 'https://www.facebook.com/oac28x',
      twitter: '',
      pinterest: '',
      behance: '',
      dribbble: ''
    },
    aboutRows:{
      i: 'I am',
      tit: 'Title',
      nam: 'Full Name',
      age: 'Age',
      ani: 'Years',
      pho: 'Phone',
      nat: 'Nationality',
      lan: 'Languages',
      ema: 'Email',
      add: 'Address',
      dow: 'Download CV'
    },
    headerBg: '/images/header_bg.png',
    headerImage: '/images/my-image.png',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/empty.pdf'
  },
  services: [
    {
      title: 'Mobile Development',
      icon: 'mobile',
      details: 'Xamarin cross platform development using C#, Xamarin Forms and Xamarin Native. Android and iOS.'
    },
    {
      title: 'Web Development',
      icon: 'code',
      details: 'Server side and SPA client side development using ReactJs or Angular. I made this site using ReactJs.'
    },
    {
      title: 'Embedded Development',
      icon: 'grid',
      details: 'Experience using PIC\'s, Atmel and Freescale MCUs. GPIO, Communication protocols, registers, timers, datasheets. Arduino.'
    },
    {
      title: 'Electronic design',
      icon: 'cpu',
      details: 'Electronic circuits design, analog and digital understanding. Experience using electronic test and measurement tools.'
    },
    {
      title: 'Wireless Communications',
      icon: 'radio',
      details: 'Knowledge on wireless communication linking, polarization, diveristy, radio power, antennas, channel access methods, etc.'
    },
    {
      title: 'Picture design',
      icon: 'image',
      details: 'Picture design and editing, main tool used Photoshop. Image assets creation to use in applications.'
    },
    {
      title: 'FrontEnd Design',
      icon: 'color-pallet',
      details: 'Knowledge on applications user experience, identity, design, colors, animations, intuitive and usable flows.'
    },
    {
      title: 'Photography',
      icon: 'camera',
      details: 'Recording the light of moments, vivid colors, nature, buildings and people. Postproduction Lightroom.'
    },
    {
      title: 'Networking & IoT',
      icon: 'globe',
      details: 'Familiar with internet networking, subnetting and internet of things.'
    }
  ],
  apps: [
    {
      icon: '/applogos/imei_app_logo.png',
      link: 'https://play.google.com/store/apps/details?id=com.oscaraaguilar.mx.imei',
      title: 'IMEI',
      subtitle: 'For Android',
      button:'Download app',
      details: [
        'If you want to know the identity data of your mobile, just download IMEI application. The app shows this data in an easy way.',
        'Saving the data is very simple since you have several options to do that. By clicking on any information box, it is copied to the clipboard. In the same way, you have the option to save the data in a text file also upload the text file to Drive.',
        'Thanks for using this application, feel free to rate and leave feedback on Play Store.'
      ] 
    },
    {
      icon: '/applogos/res_calc_app_logo.png',
      link: '',
      title: 'Resistor Calculator',
      subtitle: 'Comming soon',
      button:'Download app',
      details: [
        'If you are passionate about electronics, want to learn or need to calculate resistors with commercial and/or real values, this application will provide you with an interactive way to modify resistor values using the band resistors color code. It also has a section in which you can save elements like history, for future reference, inventories, or projects. The application allows you to add comments to each item.',
        'In one section, you can calculate resistors with different error ranges using commercial resistor values, showing series or parallel arrangements. A list of resistor options are displayed with their respective error. It will surely be very useful to you.',
        'Resistors’ calculator using the 4, 5 and 6 bands color code. Save elements to use after, historical elements. Resistor calculator using commercial values'
      ] 
    }
  ],
  privacy: {
      titles: {
        apps: 'Applications',
        privacy: 'Privacy Policy',
        links: 'External Links',
        contact: 'Contact Us',
        changes: 'Changes to our Privacy Policy',
        admobpolicy: 'https://support.google.com/admob/answer/6128543?hl=en',
        appcenterpolicy: 'https://privacy.microsoft.com/en-us/privacystatement',
        urlLink: 'link',
        email: 'oscar_aguilar_apps@outlook.com',
        baseurl: 'https://www.oscaraguilar.info/apps_privacy-policy#privacy_policy',
      },
      privacydata:[
        'Effective Date: January 12, 2020. Last revised on August 14, 2021.',
        'This Privacy Policy establishes terms in which the mobile applications we offer use and protect the information that is provided by our users when used, please take a moment to familiarize yourself with our privacy practices. We are committed to the security of our users\' data. At any time you may restrict the usage of the personal information that is provided. No data is collected at any time.',
        'BY INSTALLING THE APP ON YOUR MOBILE DEVICE, ENTERING INTO, CONNECTIONG TO, ACCESSSING AND/OR USING THE APP, YOU AGREE TO THE TERMS AND CONDITIONS SET FORTH IN THIS PRIVACY POLICY. PLEASE NOTE: IF YOU OR, AS APPLICABLE, YOUR LEGAL GUARDIAN, DISAGREE TO ANY TERM PROVIDED HEREIN, YOU MAY NOT INSTALL, ACCESS AND/OR USE THE APPS AND YOU ARE REQUESTED TO PROMPTLY ERASE THEM, AND ANY PART THEREOF FROM YOUR MOBILE DEVICE.',
        'Apps may require access to special device features, a request dialog is prompted when required. At any time you can grant or cancel access to those features.'
      ],
      linksdata:[
        'The application may contain links to external sites that may be of interest to you. Once you click on these links and leave the application, we no longer have control over the site to which you are redirected and therefore we are not responsible for the terms and privacy of users data protection. These sites have their own privacy policies, so it is recomended that you read them to comfirm that you agree with them.',
        'More specifically, we use Google Admob to display advertisements. You can consult their privacy policy here:',
        'The appliacations use Microsoft App Center, analytics and diagnostics to help us analyse how your usage of our applications. It help us to improve our products. You can read more about Microsoft privacy policy here:'
      ],
      changesdata:[
        'This Privacy Policy may be updated from time to time for any reason, we will notify you of any changes to our Privacy Policy by posting the new Privacy Policy on',
        'Your continued use of the applications and services following the posting of changes will mean you accept those changes.'
      ],
      contact: 'If you have any question regarding to privacy while using the applications or have a question about our practices, please contact us via email at'
  },
  skills: [
    {
      title: '.Net & NetCore',
      value: 85
    },
    {
      title: 'Xamarin Development',
      value: 90
    },
    {
      title: 'Bootstrap, HTML5 & CSS3',
      value: 90
    },
    {
      title: 'Native Androind & iOS',
      value: 65
    },
    {
      title: 'Javascript',
      value: 60
    },
    {
      title: 'jQuery',
      value: 75
    },
    {
      title: 'ReactJS',
      value: 80
    },
    {
      title: 'Angular',
      value: 65
    },
    {
      title: 'Git',
      value: 80
    },
    {
      title: 'Azure DevOps',
      value: 70
    },
    {
      title: 'Photoshop',
      value: 70
    },
    {
      title: 'Lightroom',
      value: 90
    },
    {
      title: 'Radio planning',
      value: 70
    },
    {
      title: 'PCB Layout',
      value: 90
    },
    {
      title: 'Embeded coding - C++',
      value: 70
    },
    {
      title: 'Electronic design and troubleshooting',
      value: 80
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: '2018 - Present',
        position: 'Frontend Web Developer',
        company: 'Abc Company',
        details: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.'
      },
      {
        id: 2,
        year: '2016 - 2018',
        position: 'Frontend Web Developer',
        company: 'CBA Company',
        details: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.'
      },
      {
        id: 3,
        year: '2014 - 1016',
        position: 'UI/UX Designer',
        company: 'Example Company',
        details: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.'
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: '2018 - 2019',
        graduation: 'Master of Science',
        university: 'Abc University',
        details: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.'
      },
      {
        id: 2,
        year: '2016 - 2018',
        graduation: 'Bachelor of Science',
        university: 'Abc University',
        details: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.'
      },
      {
        id: 3,
        year: '2015 - 2016',
        graduation: 'Higher Schoold Graduation',
        university: 'Abc College',
        details: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.'
      }
    ]
  },
  contactInfo: {
    subtitle: 'Get in touch',
    nameField: 'Enter your name',
    emailField: 'Enter your email',
    subjectField: 'Enter your subject',
    messageField: 'Enter your message',
    emailInfo: 'Email',
    addressInfo: 'Address',
    telegramInfo: 'Telegram',
    emailAddress: ['oscar_aguilar_apps@outlook.com', 'oscar_aglr@outlook.com'],
    address: 'Colima, Colima. México',
    telegram: 'https://t.me/oac28x',
    sendButton: 'Send',
    alerts: {
      nameRequired: 'Name is required.',
      emailRequired: 'Email is required.',
      subjectRequired: 'Subject is required.',
      messageRequired: 'Message is required.',
      validEmail: 'Enter a valid email.',
      invalidCaptcha: 'Invalid Captcha.',
      generalError: 'Something went wrong.',
      mesageSent: 'You message has been sent.'
    }
  }
  }

export default database_en;