import React, { useState} from "react";
import axios from 'axios';
import { Mail, MapPin, Send} from "react-feather";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import { Fade, Flip } from 'react-reveal';
import ReCAPTCHA from "react-google-recaptcha";
import { getLang } from '../data/setLang'
import apyKeys from "../data/emailKeys"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contact(props){
  const [captchaLang, setCaptchaLang] = useState(window.user.lang === "es" ? "es" : "en");
  const recaptchaRef = React.useRef();
  const lang = getLang();
  
  const [formdata, setFormdata] = useState({
    username: "",
    email: "",
    subject: "",
    message: ""
  });
  
  const setErrorToast = (message, error) => {
    switch(error){
      case 'e': //e - error
        toast.error(message, {autoClose: 2500});
      break;
      case 'i': //i - info
        toast.info(message, {autoClose: 2500});
      break;
      default:  //a - alert
        toast.warning(message, {autoClose: 2500});
      break;
    }
  }
 
  
  const submitHandler = (event) =>{
    event.preventDefault();
    if( !formdata.username ){
      setErrorToast(lang.d.contactInfo.alerts.nameRequired, 'a');
      return;
    } else if( !formdata.email ){
      setErrorToast(lang.d.contactInfo.alerts.emailRequired, 'a');
      return;
    }else if( !formdata.subject ){
      setErrorToast(lang.d.contactInfo.alerts.subjectRequired, 'a');
      return;
    } else if( !formdata.message ){
      setErrorToast(lang.d.contactInfo.alerts.messageRequired, 'a');
      return;
    }else{
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(formdata.email)) {
        setErrorToast(lang.d.contactInfo.alerts.validEmail, 'a');
        return;
      }
    }
    
    if(!recaptchaRef.current.getValue()){
      setErrorToast(lang.d.contactInfo.alerts.invalidCaptcha, 'e');
      return;
    }

    const data = {
      service_id: apyKeys.SERVICE_ID,
      template_id: apyKeys.IDTEMPLATE_ID,
      user_id: apyKeys.USER_ID,
      template_params: {
          'username': formdata.username,
          'email': formdata.email,
          'subject': formdata.subject,
          'message': formdata.message,
          'g-recaptcha-response': recaptchaRef.current.getValue()
      }
    };

    axios.post('https://api.emailjs.com/api/v1.0/email/send', data )
    .then((response) => {
      setErrorToast(lang.d.contactInfo.alerts.mesageSent, 'i');
    })
    .catch((error) => { 
      setErrorToast(lang.d.contactInfo.alerts.generalError, 'e');
    });
  }

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name] : event.currentTarget.value
    })
  }

  const redStar = <span style={{color: "#cc1122"}}>&nbsp;*</span>;

  return (
    <Layout>
      <div className="mi-contact-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
        <Sectiontitle title={lang.d.titles.contact}/>
          <div className="row">
            <div className="col-lg-6" style={{marginBottom:"-15px"}}>
              <Fade wait={50}>
                <div className="mi-contact-formwrapper">
                  <h4>{lang.d.contactInfo.subtitle}</h4>
                  <form action="#" className="mi-form mi-contact-form" onSubmit={submitHandler}>
                    <div className="mi-form-field">
                      <label htmlFor="contact-form-name">{lang.d.contactInfo.nameField}{redStar}</label>
                      <input onChange={handleChange} type="text" name="username" id="contact-form-name" value={formdata.username}/>
                    </div>
                    <div className="mi-form-field">
                      <label htmlFor="contact-form-email">{lang.d.contactInfo.emailField}{redStar}</label>
                      <input onChange={handleChange} type="text" name="email" id="contact-form-email" value={formdata.email}/>
                    </div>
                    <div className="mi-form-field">
                      <label htmlFor="contact-form-subject">{lang.d.contactInfo.subjectField}{redStar}</label>
                      <input onChange={handleChange} type="text" name="subject" id="contact-form-subject" value={formdata.subject}/>
                    </div>
                    <div className="mi-form-field">
                      <label htmlFor="contact-form-message">{lang.d.contactInfo.messageField}{redStar}</label>
                      <textarea onChange={handleChange} name="message" id="contact-form-message" cols="30" rows="6" value={formdata.message}></textarea>
                    </div>
                    <div className="mi-form-field">
                      <div className="mi-form-field-captcha">
                        <ReCAPTCHA
                          size="normal"
                          theme="dark"
                          hl={captchaLang}
                          ref={recaptchaRef}
                          sitekey="6Ld-tJ8bAAAAAOYH1O1bgZRYrTZZduA7kIy3dh10" />
                      </div>
                      <div style={{overflow:"hidden", float:"none", marginTop:"10px"}}>
                        <button style={{width:"100%"}} className="mi-button" type="submit">{lang.d.contactInfo.sendButton}</button>
                      </div>
                    </div>
                  </form>
                </div>
              </Fade>
            </div>
            <div className="col-lg-6">
              <div className="mi-contact-info">
                <Flip wait={50} delay={100} bottom>
                  <div className="mi-contact-infoblock">
                    <span className="mi-contact-infoblock-icon">
                      <Send/>
                    </span>
                    <div className="mi-contact-infoblock-content">
                      <h6>{lang.d.contactInfo.telegramInfo}</h6>
                      <p><a href={lang.d.contactInfo.telegram} rel="noopener noreferrer" target="_blank">@oac28x</a></p>
                    </div>
                  </div>
                </Flip>
                <Flip wait={50} delay={200} bottom>
                  <div className="mi-contact-infoblock">
                    <span className="mi-contact-infoblock-icon">
                      <Mail/>
                    </span>
                    <div className="mi-contact-infoblock-content">
                      <h6>{lang.d.contactInfo.emailInfo}</h6>
                      {lang.d.contactInfo.emailAddress.map(email => (
                        <p key={email}><a rel="noopener noreferrer" href={`mailto:${email}`}>{email}</a></p>
                      ))}
                    </div>
                  </div>
                </Flip>
                <Flip wait={50} delay={400} bottom>
                  <div className="mi-contact-infoblock">
                    <span className="mi-contact-infoblock-icon">
                      <MapPin/>
                    </span>
                    <div className="mi-contact-infoblock-content">
                      <h6>{lang.d.contactInfo.addressInfo}</h6>
                      <p>{lang.d.contactInfo.address}</p>
                    </div>
                  </div>
                </Flip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
}

export default Contact;
