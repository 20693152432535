import React, { Component} from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ReactGA from "react-ga";
import "./App.scss";
import Home from "./pages/Home";
import About from "./pages/About";
import Resumes from "./pages/Resumes";
import Contact from "./pages/Contact";
import Apps from "./pages/Apps";
import RealSamesta from "./pages/RealSamesta";
import Notfound from "./pages/Notfound";



class App extends Component {
  constructor(props) {
    super(props)

    ReactGA.initialize('G-07FDB1YPH9');

    //SetGlobalProps
    window.user = {
      lang: "en"
    }

    var lang = new URLSearchParams(window.location.search).get("lang");
    if(lang) this.setLanguage(lang);
  }

  setLanguage = (lan) =>{
    window.user = {
      lang: lan
    }
  }

  componentDidMount  = () => ReactGA.pageview(window.location.pathname + window.location.search);
  componentDidUpdate = () => ReactGA.pageview(window.location.pathname + window.location.search);

  render(){ 
    return(
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path="/about" component={About} />
          <Route path="/resume" component={Resumes} />
          <Route path="/contact" component={Contact} />
          <Route path="/apps_privacy-policy" component={Apps} />
          <Route path="/real_samesta" component={RealSamesta} />
          <Route path="*" component={Notfound} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
