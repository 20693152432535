import React, { useState, useEffect } from 'react';
import {NavLink, Link} from 'react-router-dom';
import LineIcon from 'react-lineicons';
import { getLang, setLang } from '../data/setLang'
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router';

import database_en from "../data/db/database_en"
import database_es from "../data/db/database_es"

const Header = (props) => {
    const lang = getLang();
    const [navigationToggler, setNavigationToggler] = useState(false);
    const [langClicked, userClickedLanguage] = useState(false);
    const [realSamestaCheck, realSamestaSet] = useState(false);

    const handleNavigationToggler = () => {
        setNavigationToggler(!navigationToggler);
    }

    const changeLanguage = () =>{
        userClickedLanguage(true);
        setLanguage(lang.lang === 'en' ? 'es' : 'en');
    }

    useEffect(() => {
        if(langClicked) return;
        if(window.user.lang) setLanguage(window.user.lang);
        realSamesta();
        if(realSamestaCheck){
          console.log("entro al if");
          var elements = document.getElementsByClassName('mi-header');
          for (let element of elements) {
            element.parentElement?.remove();
          }
    
          var elements = document.getElementsByClassName('mi-wrapper');
          for (let element of elements) {
            element.style.paddingLeft = "0px";
          }
        }
    });

    const realSamesta = () => {
        var lastPart =  window.location.href.split('/').pop();
        realSamestaSet(lastPart === "real_samesta");
    }

    const setLanguage = (lan) =>{
        switch(lan){
            case 'es':
                if(lang.lang == 'es') return; 
                setLang({'lang': 'es', 'd': database_es});
                break;
            case 'en':
                if(lang.lang == 'en') return;
                setLang({'lang': 'en', 'd': database_en});
                break;
            default:
                break;
        }
    }

    const hClasses = {
        'head': 'mi-header',
        'toggler': 'mi-header-toggler',
        'inner': 'mi-header-inner',
        'imagebg': 'mi-header-imagebg',
        'image': 'mi-header-image',
        'menu': 'mi-header-menu',
        'copyright': 'mi-header-copyright'
    }

    const hmClasses = {
        'head': 'mi-header-mobile',
        'toggler': 'mi-header-mobile-toggler',
        'inner': 'mi-header-mobile-inner',
        'imagebg': 'mi-header-mobile-imagebg',
        'image': 'mi-header-mobile-image',
        'menu': 'mi-header-mobile-menu',
        'copyright': 'mi-header-mobile-copyright'
    }

    const h = isMobile ? hmClasses : hClasses;

    return (
        <div style={realSamestaCheck ? {display: 'none'} : {}}> 
            {navigationToggler ? <div onClick={handleNavigationToggler} className="mi-header-transparent"/> : null}
            <nav className={navigationToggler ? h.head + " is-visible" : h.head }>
                <button onClick={handleNavigationToggler} className={h.toggler}>
                    {!navigationToggler ? <LineIcon name="menu" /> : <LineIcon name="close" />}
                </button>
                <div className={h.inner}>
                    <div className={h.image}>
                        <img className={h.imagebg} src={lang.d.information.headerBg} alt="header"/>
                        <Link to="/">
                            <img src={lang.d.information.headerImage} alt="myImage"/>
                        </Link>
                    </div>
                    
                    <ul className={h.menu}>
                        <li><NavLink exact to="/"><span>{lang.d.menu.home}</span></NavLink></li>
                        <li><NavLink to="/about"><span>{lang.d.menu.about}</span></NavLink></li>
                        <li><NavLink to="/resume"><span>{lang.d.menu.resume}</span></NavLink></li>
                        <li><NavLink to="/contact"><span>{lang.d.menu.contact}</span></NavLink></li>
                        <div className="menu-divider"/>
                        <li><NavLink to="/apps_privacy-policy"><span>Apps</span></NavLink></li>
                    </ul>
                    <p className={h.copyright}><button onClick={changeLanguage}>{(lang.lang === 'es') ? 'English' : 'Español'}</button></p>
                </div>
            </nav>
        </div>
    );
}

export default withRouter(Header);