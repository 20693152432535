import React from "react";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import { getLang } from '../data/setLang'
import { Fade, Flip } from 'react-reveal';
import Particles from "react-particles-js";

function Notfound() {
  const lang = getLang();
  const paramConfig = {
    particles: {
      number: {
        value: 130,
        density: {
          enable: false
        }
      },
      color: {
        value: "#ffffff"
      },
      opacity: {
        value: 0.1
      },
      size: {
        value: 6,
        random: true,
        anim: {
          enable: true,
          speed: 3
        }
      },
      line_linked: {
        enable: false
      },
      move: {
        random: true,
        speed: 1,
        direction: "bottom",
        out_mode: "out"
      }
    }
  };
  return (
    <Layout>
      <Particles className="mi-home-particle" params={paramConfig} />
      <div className="mi-about-area mi-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="notfound">
                <div className="notfound-inner text-center">
                  <Flip wait={10} bottom><h1>{lang.d.notFound.number}</h1></Flip>
                  <Fade wait={10} bottom><h3>{lang.d.notFound.message}</h3></Fade>
                  <Fade wait={10} bottom><h5>{lang.d.notFound.advice}</h5></Fade>
                  <Fade wait={10} delay={300}>
                    <Link to="/" className="mi-button">
                      {lang.d.notFound.button}
                    </Link>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Notfound;
